import React, { useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { Button, Spin, Col, DatePicker, message, Drawer, Alert, Form, Input, Row, Select, Space, Layout, theme } from 'antd';
import { Link } from 'react-router-dom';



import '../../App.css'
import ItensMenu from '../../components/ItensMenu';
import LogoMarker from '../../components/LogoMarker';
import ItemHeader from '../../components/ItemHeader';
import ItemSideBar from '../../components/ItemSideBar';

import axios from 'axios';
import ItemHeaderCabecalho from '../../components/ItemHeaderCabecalho';

const { Header, Sider, Content } = Layout;
const { Option } = Select;


const URL_API = "http://127.0.0.1:8080";


const CadastroFormFuncao = ({ onFinish, loading, initialValues, back }) => {

    const [salvando, setSalvando] = useState(false);
    const [form] = Form.useForm(); // Create a form instance

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    return (

        <>

            <Content

                style={{

                    marginTop: 20,
                    width: '100%',
                    maxHeight: 500,


                    borderRadius: 0,
                }}
            >

                <div className='form-cadastro form-cadastro-mini'>
                    <ItemHeaderCabecalho
                        title="Cadastro de Funções"
                    />
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        initialValues={initialValues}
                        style={{ padding: 20, paddingTop: 5 }}

                    >
                        <Form.Item
                            name="descricao"
                            label="Descrição da Função"
                            rules={[{ required: true, message: 'Por favor, insira a descrição da função' }]}
                        >
                            <Input placeholder="Por favor, insira a descrição da função" size="large" />
                        </Form.Item>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item>
                                    <Button type="primary" size="large" htmlType="submit" style={{ width: '100%' }}>
                                        {initialValues ? 'Atualizar' : 'Cadastrar'}
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item>
                                    <Link onClick={back} className='btn-cancel'>Voltar</Link>
                                </Form.Item>

                            </Col>
                        </Row>





                    </Form>
                </div>


            </Content>
        </>





    );



};

export default CadastroFormFuncao;