import React, { useEffect, useState, useMemo } from 'react';






import axios, { isAxiosError } from 'axios';


import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { DotChartOutlined, LineChartOutlined, LoadingOutlined } from '@ant-design/icons';
import { WarningOutlined, } from '@ant-design/icons';
import { ExperimentTwoTone } from '@ant-design/icons'

import IconChart from '../../assets/Chart.svg';
import iconFileText from '../../assets/file-text.svg';
import IconProduction from '../../assets/Production.svg';
import IconAcompanhamento from '../../assets/Acompanhamento.svg';


import { Layout, Menu, Button, theme, Flex, Divider, Modal, Table, Space, message, Alert, Spin, notification } from 'antd';
import { Link } from 'react-router-dom';


import IconDelete from '../../assets/DeleteIcone.png';
import IconView from '../../assets/view.svg';
import IconEdit from '../../assets/edit.svg';

import '../../App.css'
import ItensMenu from '../../components/ItensMenu';
import LogoMarker from '../../components/LogoMarker';
import ItemHeader from '../../components/ItemHeader';
import ItemSideBar from '../../components/ItemSideBar';
import CadastroFormFuncao from './CadastroFormFuncao';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { PieChartOutline, PieChartOutlineOutlined, SmsFailedOutlined } from '@mui/icons-material';
import { Progress } from 'antd';
import TankChartLine from '../../components/TankChartLine';
import TemperatureIcon from '../../components/TemperatureIcon';
import TankIcon from '../../components/TankIcon';
import TankIconMini from '../../components/TankIconMini';
import FiltroNivelHistorico from '../../components/FiltroNivelHistorico';
import ItemLoading from '../../components/ItemLoaging';
import TankLineChartDuplex from '../../components/TankChartLineDuplex';

const { Header, Sider, Content } = Layout;

const Context = React.createContext({
  name: 'Default',
});

//const URL_API = "http://localhost:8000";
const URL_API = "http://77.37.68.38:8000"

const Funcao = () => {

  const [collapsed, setCollapsed] = useState(false);

  const [data, setData] = useState([]);
  const [gerenciando, setGerenciando] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [numeroMedicaoDiaria, setNumeroMedicaoDiaria] = useState(null)


  const contextValue = useMemo(
    () => ({
      name: 'Ant Design',
    }),
    [],
  );


  useEffect(() => {
    fetchData();
  }, []);

  const openNotification = (erro) => {
    notification.open({
      message: 'Aviso',
      icon: <WarningOutlined style={{ color: 'red' }} />,
      description:
        'Não foi possível realizar a operação! ' + erro,
      onClick: () => {
        console.log('Notification Clicked!');
      },
    });
  };


  const fetchData = async () => {
    try {
      setLoading(true)
     
      const response = await axios.get(`${URL_API}/api/medicoes-hoje-todas/`);
      setData(response.data);

      loadQuantidade()
      setTimeout(()=>{
        setLoading(false)
      }, 2000)

      


    } catch (error) {
      setLoading(false)
      setError(error);

    } finally {
      setTimeout(() => { setLoading(false); }, 1000)


    }
  }

  async function loadQuantidade() {
    const responseNumeroMedicao = await axios.get(`${URL_API}/api/medicoes-hoje/`);
    setNumeroMedicaoDiaria(responseNumeroMedicao.data.quantidade_medicoes);
  }

  



  const paginationConfig = {
    pageSize: 4,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total, range) => `Linhas por páginas  ${4}   ${range[0]}-${range[1]}/${total} `,
  };



  const columns = [

    {
      title: 'Tanque',
      dataIndex: 'tanque',
      width: '20%',

    },

    {
      title: 'Local',
      dataIndex: 'local',
      width: '20%',

    },

    {
      title: 'Nível',
      dataIndex: 'sensor_nivel',
      width: '20%',

    },
    {
      title: 'Temperatura ',
      dataIndex: 'sensor_temp',
      width: '20%',

    },
    {
      title: 'Data da Medição ',
      dataIndex: 'data',
      width: '20%',
      render: (text) => {
        const date = new Date(text);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      }

    },


    {
      render: (_, record) => (
        <Space size="middle">
          <a ><img src={IconView} className='icon-view-table' /></a>

        </Space>
      ),
    },

  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };


  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const twoColors = {
    '0%': '#e2aa53',  // Vermelho claro no início
    '100%': '#cc0000',  // Vermelho escuro no final
  };
  const conicColors = {
    '0%': '#87d068',
    '50%': '#ffe58f',
    '100%': '#ffccc7',
  };



  return (
    <Layout style={{ minHeight: '100vh' }}>
      <ItemSideBar />

      <Layout >

        <ItemHeader title="Tanque 001" />

        <Flex className='cards'>

          <div className='card'>
            <div className='card-info-first'>
              <TemperatureIcon></TemperatureIcon>

            </div>
            <div className='card-info-last'>
              <span>Temperatura</span>
              <Flex
                vertical
                gap="small"
                style={{
                  width: 180,
                }}
              >
                <Progress type="line" percent={90} strokeColor={twoColors} strokeWidth={15} />
              </Flex>
            </div>
          </div>

          <div className='card'>
            <div className='card-info-first'>
              <TankIconMini></TankIconMini>
            </div>
            <div className='card-info-last'>
              <span>Nível Atual</span>
              <Flex wrap gap="middle" style={{ marginTop: 10 }}>
                <Progress
                  type="dashboard"
                  status='normal'
                  steps={8}
                  percent={70}
                  trailColor="rgba(0, 0, 0, 0.06)"
                  twoToneColor="rgba(200, 000, 255, 0.6)"
                  strokeWidth={20}
                />
              </Flex>
            </div>
          </div>


          <div className='card card-first info-medition'>
            <h2>Medições Recebidas Hoje </h2>
            <div className='info-medition-data'>

              <DotChartOutlined style={{ color: '#FFF755', fontSize: 30 }} />
              <p>{numeroMedicaoDiaria}</p>
            </div>

          </div>

          <div className='card card-first info-medition info-medition-color-two'>
            <h2>Medições Recebidas Hoje </h2>
            <div className='info-medition-data'>
              <LineChartOutlined style={{ color: '#FFF', fontSize: 30 }} />
              <p>{numeroMedicaoDiaria}</p>

            </div>


          </div>
        </Flex>

        <Flex className='cards-chart-large'>
          <div className='card-chart-large-item-1'>
            {
              loading ?
                <ItemLoading></ItemLoading>
                :
               <TankLineChartDuplex data={data}/>
            }

          </div>

          <div className='card-chart-large-item-2'>
            <FiltroNivelHistorico />
          </div>



        </Flex>


      </Layout>
    </Layout>
  );



};

export default Funcao;