import React from 'react';
import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
  Link,
} from "react-router-dom";
import { Layout, Menu } from 'antd';

import Dashboard from './pages/Dashboard';

import Funcao from './pages/Funcao';




const { Header, Content } = Layout;

const App = () => {
  return (
    
     
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/resume" element={<Funcao />} />
              
            </Routes>
          </BrowserRouter>
       
    
  );
};

export default App;
