
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/nivelAguaCinza.png';


export default function TankIconDisable() {

   
        return (
            <div className="demo-logo-vertical" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 50 }}>
               <img src={Logo} style={{ height: 130,width:100, marginTop: 0, marginBottom:40, marginLeft: 10, marginRight:10 }} />
            </div>
        );
  

    
}