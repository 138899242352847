
import React from 'react';

const TempChart = ({ temp }) => {

    const divStyle = {
        width: '70px',
        height: '130px',
        background: 'linear-gradient(to top, #ff6347 10%, #ffffff 80%)',
        border: '1px solid #ccc',
        borderRadius: `${4}px`,
        display: 'flex', // Habilita o flexbox
        alignItems: 'center', // Alinha o texto verticalmente ao centro
        justifyContent: 'center', // Alinha o texto horizontalmente ao centro
        textAlign: 'center',
        fontSize: '18px',
        fontWheigth:'bold' // Garante que o texto dentro seja centralizado (caso seja multilinha)
    };

    return(
        <div style={divStyle}> 
            <p>{temp}</p>
        </div>
    )
}

export default TempChart;