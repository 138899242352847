
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/logo-river.png';
import LogoMini from '../../assets/logo-river-mini.png';

export default function LogoMarker({collapsed}) {

   
        return (
            <div className="demo-logo-vertical" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 50 }}>
                <Link to="/"><img src={collapsed ? LogoMini : Logo} style={{ height: collapsed ? 40: 180, marginTop: 30, marginBottom:40, marginLeft: 20, marginRight:20 }} /></Link>
            </div>
        );
  

    
}