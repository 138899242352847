
import React, { useState } from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UserAddOutlined,
    AreaChartOutlined,
    BarChartOutlined
} from '@ant-design/icons';






import { Layout, Menu, Button, theme, Flex, Divider } from 'antd';
import { Link } from 'react-router-dom';


import IconChart from '../../assets/Chart.svg';
import iconFileText from '../../assets/file-text.svg';
import IconProduction from '../../assets/Production.svg';
import IconAcompanhamento from '../../assets/Acompanhamento.svg';

import LogoMarker from '../../components/LogoMarker';

import '../../App.css'
import ItensMenu from '../../components/ItensMenu';
import ItemHeader from '../../components/ItemHeader';

const { Header, Sider, Content } = Layout;

export default function ItemSideBar() {

    const [collapsed, setCollapsed] = useState(false);
    const [positionButton, setPositionButton] = useState('right');

    const [selectedKey, setSelectedKey] = useState('1'); // estado para armazenar a chave do item selecionado

    const handleMenuClick = (e) => {
        setSelectedKey(e.key);
    };

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Sider className='menu' trigger={null} collapsible collapsed={collapsed} style={{ padding: 0, background: '#5f767f', borderRight: '2px solid #e8e8e8' }}>


            <LogoMarker collapsed={collapsed} />


            <div style={{ textAlign: positionButton }} className='position-btn'>
                <Button
                    className='position-btn'
                    type="text"
                    icon={collapsed ? <MenuUnfoldOutlined className='position-btn' /> : <MenuFoldOutlined className='position-btn' />}
                    onClick={() => setCollapsed(!collapsed)}
                    style={{
                        fontSize: '20px',
                       
                        height:100,
                        
                        color:'#FFF',
                        width:'100%',
                        textAlign:'right',
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'center'

                        

                    }}
                />
            </div>

            <ItensMenu />


        </Sider>
    );
}