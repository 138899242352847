import React, { useEffect, useState } from 'react';
import { DatePicker, Button, Table, message } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const FiltroNivelHistorico = () => {
  const [datas, setDatas] = useState([]);
  const [dados, setDados] = useState([]);
  const [containsData, setContainsData] = useState(true)
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 4, // Número de registros por página
    total: 0, // Total de registros, atualiza quando buscamos os dados
  });

  // Função para lidar com a seleção de datas
  const handleRangeChange = (dates) => {
    if (dates) {
      setDatas([dates[0], dates[1]]);
    } else {
      setDatas([]);
    }
  };

  //const URL_API = "http://localhost:8000";
  const URL_API = "http://77.37.68.38:8000"

  const fetchDataHoje = async () => {
    setContainsData(true)
    try {
      const response = await axios.get(`${URL_API}/api/medicoes-hoje-todas/`);
      
      // Atualizar os dados e a paginação
      setDados([])
      setDados(response.data);
      setPagination({
        ...pagination,
        current: 5,
        total: response.data.count,
      });

      if(!dados.length > 0){
         setContainsData(false)
      }
    } catch (error) {
      message.error('Erro ao buscar os dados hoje.', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataHoje();
  }, []);

  // Função para buscar os dados da API
  const fetchDados = async (page = 1) => {
    if (datas.length !== 2) {
      message.error('Por favor, selecione um intervalo de datas válido.');
      return;
    }

    let data_inicio = datas[0].format('YYYY-MM-DD');
    let data_fim = datas[1].format('YYYY-MM-DD');

    // Se as datas forem iguais, ajusta o data_fim para incluir o dia inteiro
    if (datas[0].isSame(datas[1], 'day')) {
      data_fim = datas[1].add(1, 'day').format('YYYY-MM-DD');
    }

    setLoading(true);

    try {
      const response = await axios.get(`${URL_API}/api/historico-por-data/`, {
        params: {
          data_inicio,
          data_fim,
          page,
          page_size: pagination.pageSize, // Enviando o tamanho da página
        },
      });

      // Atualizar os dados e a paginação
      setDados([])
      setDados(response.data);
      setPagination({
        ...pagination,
        current: page,
        total: response.data.count,
      });
    } catch (error) {
      message.error('Erro ao buscar os dados.', error);
    } finally {
      setLoading(false);
    }
  };

  // Função para lidar com a mudança de página
  const handleTableChange = (pagination) => {
    fetchDados(pagination.current);
  };

  // Colunas da tabela de resultados
  const columns = [
    { title: 'Local', dataIndex: 'local', key: 'local' },
    { title: 'Tanque', dataIndex: 'tanque', key: 'tanque' },
    { title: 'Nível do Sensor', dataIndex: 'sensor_nivel', key: 'sensor_nivel' },
    { title: 'Temperatura do Sensor', dataIndex: 'sensor_temp', key: 'sensor_temp' },
    { title: 'Data', dataIndex: 'data', key: 'data', render: (text) => dayjs(text).format('DD/MM/YYYY') },
  ];

  return (
    <div style={{ width: '100%' }}>
      <h2 style={{ marginBottom: 10 }}>Histórico de Medições</h2>
      <RangePicker
        format="YYYY-MM-DD"
        onChange={handleRangeChange}
        style={{ maxWidth: '70%' }}
      />
      <Button type="primary" onClick={() => fetchDados(1)} disabled={loading} style={{ marginLeft: 10 }}>
        Buscar
      </Button>

      

      <Table
        dataSource={dados}
        columns={columns}
        rowKey="id"
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        loading={loading}
        onChange={handleTableChange}
        style={{ marginTop: 20 }}
      />
    </div>
  );
};

export default FiltroNivelHistorico;
