import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Componente funcional recebendo dados como props
const TankLineChartDuplex = ({ data }) => {
  // Formatando a data para melhor visualização no gráfico
  const formattedData = data.map(item => ({
    ...item,
    data: new Date(item.data).toLocaleTimeString() // Ajuste o formato da data conforme necessário
  }));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={formattedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="data" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="sensor_nivel" stroke="#8884d8" strokeWidth={1}activeDot={{ r: 8 }} />
        <Line type="monotone" dataKey="sensor_temp" stroke="#82ca9d" strokeWidth={1}/>
      </LineChart>
    </ResponsiveContainer>
  );
};

export default TankLineChartDuplex;
