import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Função para formatar a data para algo legível
const formatData = (dataString) => {
    const date = new Date(dataString);
    return date.toLocaleString();
};



// Componente TankChartLine que agora recebe os dados via props
const TankChartLine = ({ chartData }) => {
  // Preparando os dados para o gráfico
  const data = chartData.map(item => ({
    name: formatData(item.data), // Formatar a data para algo legível
    sensor_nivel: item.sensor_nivel, // Usar o sensor_nivel no gráfico
    sensor_temp: item.sensor_temp // Caso queira plotar também temperatura
  }));

  return (
    <ResponsiveContainer width="100%" height={400}>
      
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 10,
          left: 10,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="bumpX" dataKey="sensor_nivel" strokeWidth={3} stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default TankChartLine;
