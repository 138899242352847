import React, { useEffect, useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UserAddOutlined,
  AreaChartOutlined,
  BarChartOutlined
} from '@ant-design/icons';

import axios from 'axios';

import { Progress, Layout, Menu, Button, theme, Flex, Divider, Alert, notification } from 'antd';
import { Link } from 'react-router-dom';


import IconChart from '../assets/Chart.svg';
import iconFileText from '../assets/file-text.svg';
import IconProduction from '../assets/Production.svg';
import IconAcompanhamento from '../assets/Acompanhamento.svg';

import LogoMarker from '../components/LogoMarker';

import '../App.css'
import ItensMenu from '../components/ItensMenu';
import ItemHeader from '../components/ItemHeader';
import ItemSideBar from '../components/ItemSideBar';
import TankChart from '../components/TankChartPie';
import TankIcon from '../components/TankIcon';
import TankIconDisable from '../components/TankIconDisable';
import { Line } from 'recharts';
import { Flag, TimesOneMobiledata } from '@mui/icons-material';
import TempChart from '../components/TempChart';


const { Header, Sider, Content } = Layout;

const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [positionButton, setPositionButton] = useState('right');
  const [temperatura, setTemperatura] = useState(0)

  const [id, setId] = useState('');
  const [local, setLocal] = useState("");
  const [tanque, setTanque] = useState('');
  const [nivel, setNivel] = useState('');
  const [numeroRequisicao, setNumeroRequisicao] = useState(0);
  const [loading, setLoading] = useState(false)
  const [erroLloading, setErroLoading] = useState(false)
  const [textoSincronizacao, setTextoSincronizacao] = useState('Sincronizando dados com a embarcação....')

  const [data, setData] = useState('');

  const URL_API = "http://77.37.68.38:8000"

  const load = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${URL_API}/api/plc-status-valores/`);
      setTanque(response.data[0].tanque)
      setTemperatura(response.data[0].sensor_temp)
      setNivel(response.data[0].sensor_nivel)



      setLoading(false)
    } catch (error) {
      setErroLoading(true)
      setLoading(false)

    }
  }

  useEffect(() => {
    setErroLoading(false)
    load();
  }, [])


  setInterval(() => {
    load()
  }, 50000)

  const [selectedKey, setSelectedKey] = useState('1'); // estado para armazenar a chave do item selecionado

  const handleMenuClick = (e) => {
    setSelectedKey(e.key);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const twoColors = {
    '0%': '#e2aa53',  // Vermelho claro no início
    '100%': '#cc0000',  // Vermelho escuro no final

  };
  const conicColors = {
    '0%': '#87d068',
    '50%': '#ffe58f',
    '100%': '#ffccc7',
  };

  const [api, contextHolder] = notification.useNotification();

  const openNotification = () => {
    api.open({
      message: 'Erro de conexão',
      description: 'Erro de conexão com a API.',
      type: 'warning',
      duration: 0,
      style: {
        background: twoColors.background,
        color: twoColors.text,
      },
    });
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {contextHolder}
      <ItemSideBar />
      <Layout  >

        <ItemHeader />

        {
          loading ?

            <Alert
              message=""
              description={textoSincronizacao}
              type="warning"

              closable


              style={{
                padding: '10px',
                margin: '20px',

                color: '#FFF'
              }}
            /> : <></>
        }



        <Flex className='cards-chart'>
          <div className='card-chart'>

            <div className='card-info'>
              <div style={{ display: 'flex', justifyContent: 'center', gap: '10', alignItems: 'center', justifyItems: 'baseline' }}>
                <TankIcon />
                <TempChart temp={temperatura}></TempChart>
              </div>
              <div className='card-info-chart'>
                <div >
                  <div className='card-info-last'>
                    <span>Tanque {tanque}</span>

                    <Flex wrap gap="middle" style={{ marginTop: 10 }}>
                      <Progress
                        type="dashboard"
                        status='normal'
                        steps={8}
                        percent={70}
                        trailColor="rgba(0, 0, 0, 0.06)"
                        twoToneColor="rgba(200, 000, 255, 0.6)"
                        strokeWidth={20}
                      />
                    </Flex>
                  </div>



                </div>


              </div>
            </div>

            <div className='card-action'>
              <Link to="/resume" className='btn-create' title={tanque}>Acessar</Link>
            </div>


          </div>

          <div className='card-chart'>

            <div className='card-info'>
              <TankIconDisable />
              <div className='card-info-chart'>
                <div >
                  <div className='card-info-last'>
                    <span></span>

                    <Flex
                      vertical
                      gap="small"
                      style={{
                        width: '90%',
                      }}
                    >
                      <Progress type="dashboard" percent={0} strokeColor={twoColors} strokeWidth={10} />
                    </Flex>
                  </div>

                  <div className='card-info-last'>
                    <span>Temperatura</span>
                    <Flex
                      vertical
                      gap="small"
                      style={{
                        width: '90%',

                      }}
                    >
                      <Progress type="line" percent={0} strokeColor={conicColors} strokeWidth={20} />
                    </Flex>
                  </div>

                </div>


              </div>
            </div>

            <div className='card-action'>
              <Link disable={true} to="/resume" className='btn-create'>Acessar</Link>
            </div>


          </div>

          <div className='card-chart'>

            <div className='card-info'>
              <TankIconDisable />

              <div className='card-info-chart'>
                <div >
                  <div className='card-info-last'>
                    <span></span>

                    <Flex
                      vertical
                      gap="small"
                      style={{
                        width: '90%',
                      }}
                    >
                      <Progress type="dashboard" percent={0} strokeColor={twoColors} strokeWidth={10} />
                    </Flex>
                  </div>

                  <div className='card-info-last'>
                    <span>Temperatura</span>
                    <Flex
                      vertical
                      gap="small"
                      style={{
                        width: '90%',

                      }}
                    >

                    </Flex>
                  </div>

                </div>


              </div>
            </div>

            <div className='card-action'>
              <Link disable={true} to="/resume" className='btn-create'>Acessar</Link>
            </div>


          </div>

          <div className='card-chart'>

            <div className='card-info'>
              <TankIconDisable />
              <div className='card-info-chart'>
                <div >
                  <div className='card-info-last'>
                    <span></span>

                    <Flex
                      vertical
                      gap="small"
                      style={{
                        width: '90%',
                      }}
                    >
                      <Progress type="dashboard" percent={0} strokeColor={twoColors} strokeWidth={10} />
                    </Flex>
                  </div>

                  <div className='card-info-last'>
                    <span>Temperatura</span>
                    <Flex
                      vertical
                      gap="small"
                      style={{
                        width: '90%',

                      }}
                    >
                      <Progress type="line" percent={0} strokeColor={conicColors} strokeWidth={20} />
                    </Flex>
                  </div>

                </div>


              </div>
            </div>

            <div className='card-action'>
              <Link disable={true} to="/resume" className='btn-create'>Acessar</Link>
            </div>


          </div>




        </Flex>



      </Layout>
    </Layout>
  );
};

export default Dashboard;