
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/nivelTank.png';


export default function TankIconMini() {

   
        return (
            <div className="demo-logo-vertical" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 50 }}>
               <img src={Logo} style={{ height: 100,width:100, marginTop: 30, marginBottom:40, marginLeft: 10, marginRight:10 }} />
            </div>
        );
  

    
}